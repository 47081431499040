import React, { useState } from "react";
import YouTubePlayer from "./YouTubePlayer.tsx";

interface VideoData {
  videoId: string;
  thumbnail: string;
  countryCode: string;
}
interface VideoSelectorProps {
  countryCode: string;
}

const videoData: VideoData[] = [
  {
    videoId: "VdutBId2sb4",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "DK",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "DK",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "DK",
  },
  {
    videoId: "WWra_RTuTj0",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "DE",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "DE",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "DE",
  },
  {
    videoId: "6UYI5HiDp8Q",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "ES",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "ES",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "ES",
  },
  {
    videoId: "zjgE21GKQe4",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "FI",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "FI",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "FI",
  },
  {
    videoId: "xRJaTeX28vc",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "FR",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "FR",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "FR",
  },
  {
    videoId: "5BQqGvgl8JQ",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "IT",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "IT",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "IT",
  },
  {
    videoId: "O023v2JxJ7E",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "SE",
  },
  {
    videoId: "PVeTCZD3aqw",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "SE",
  },
  {
    videoId: "ooDuIrJKG_s",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "SE",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "GB",
  },
  {
    videoId: "yv0SS3BBhaA",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "GB",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "GB",
  },
  {
    videoId: "YLY0MDDTSzs",
    thumbnail:
      "/img/backgrounds/hivan-arvizu-soyhivan-MAnhvw0nDDY-unsplash.webp",
    countryCode: "US",
  },
  {
    videoId: "yv0SS3BBhaA",
    thumbnail: "/img/backgrounds/brooke-cagle-NoRsyXmHGpI-unsplash.webp",
    countryCode: "US",
  },
  {
    videoId: "Bz60JEK8hCo",
    thumbnail: "/img/backgrounds/brooke-cagle-jJptOLhaQpw-unsplash.webp",
    countryCode: "US",
  },
];

const PromoVideo = ({ countryCode }: VideoSelectorProps) => {
  const effectiveCountryCode = [
    "DK",
    "DE",
    "ES",
    "FI",
    "FR",
    "GB",
    "IT",
    "SE",
    "US",
  ].includes(countryCode)
    ? countryCode
    : "GB";

  const filteredVideoData = videoData.filter(
    (video) => video.countryCode === effectiveCountryCode
  );

  const [selectedVideoId, setSelectedVideoId] = useState<string>(
    filteredVideoData[0].videoId
  );

  return (
    <div>
      {selectedVideoId && <YouTubePlayer videoId={selectedVideoId} />}
      <div className="thumbnail-container flex justify-center mt-10 space-x-3 w-full">
        {filteredVideoData?.map(({ videoId, thumbnail }, index) => (
          <img
            key={index}
            src={thumbnail}
            sizes="(max-width: 600px) 100px, (max-width: 1200px) 200px, 400px"
            alt={`Thumbnail ${index + 1}`}
            onClick={() => setSelectedVideoId(videoId)}
            className={`flex-1 cursor-pointer object-cover rounded-xl ${
              videoId === selectedVideoId
                ? "border-white border-4 opacity-100"
                : "border-lightPurple border-2 opacity-30"
            }`}
            style={{ width: "100px", maxWidth: "calc(33.333% - 8px)" }}
          />
        ))}
      </div>
    </div>
  );
};

export default PromoVideo;
